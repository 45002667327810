<template>
    <!--begin::Menu wrapper-->
    <div
        class="header-menu align-items-stretch"
        data-kt-drawer="true"
        data-kt-drawer-name="header-menu"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
        data-kt-place="true"
        data-kt-place-mode="prepend"
        data-kt-place-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
        <!--begin::Menu-->
    </div>
    <!--end::Menu wrapper-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";

export default defineComponent({
    name: "KTMenu",
    components: {},
    setup() {
        const { t, te } = useI18n();
        const route = useRoute();

        const hasActiveChildren = (match) => {
            return route.path.indexOf(match) !== -1;
        };

        const translate = (text) => {
            if (te(text)) {
                return t(text);
            } else {
                return text;
            }
        };

        onMounted(() => {
            MenuComponent.reinitialization();
        });

        return {
            hasActiveChildren,
            headerMenuIcons,
            MainMenuConfig,
            translate,
            version,
        };
    },
});
</script>
