<template>
    <!--begin::Menu-->
    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
        <!--begin::Menu item-->
        <div class="menu-item px-3">
            <div class="menu-content d-flex align-items-center px-3">
                <div class="d-flex flex-column">
                    <div class="fw-bolder d-flex align-items-center fs-5">
                      {{ this.$store.getters.currentUser.user.userprofile.firstname }} {{ this.$store.getters.currentUser.user.userprofile.lastname }}</div>
                    <a href="#" class="fw-bold text-muted text-hover-primary fs-7">{{ this.$store.getters.currentUser.user.email }}</a>
                </div>
                <!--end::Username-->
            </div>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu separator-->
        <div class="separator my-2"></div>
        <!--end::Menu separator-->
        <!--begin::Menu item-->
        <div class="menu-item px-5">
            <a @click="signOut()" class="menu-link px-5"> {{ $t("layout.header.logout") }} </a>
        </div>
        <!--end::Menu item-->
    </div>
    <!--end::Menu-->
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
    name: "kt-user-menu",
    components: {},
    setup() {
        const router = useRouter();
        const store = useStore();

        const signOut = () => {
            store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "sign-in" }));
        };

        return {
            signOut,
        };
    },
});
</script>
