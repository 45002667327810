<template>
    <!--begin::Toolbar wrapper-->
    <div class="d-flex align-items-stretch flex-shrink-0">
        <!--begin::User-->
        <div class="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
            <div
                class="text-end me-5"
                v-if="
                    this.$store.getters.currentSettings &&
                    this.$store.getters.currentSettings.en &&
                    this.$store.getters.currentSettings.fr &&
                    this.$route.name !== 'administration-settings'
                "
            >
                <a v-if="$i18n.locale === 'en'" v-on:click="setLocale('fr')" class="btn btn-icon btn-light">
                    <img alt="Logo" src="/media/france.png" class="w-50" />
                </a>
                <a v-else-if="$i18n.locale === 'fr'" v-on:click="setLocale('en')" class="btn btn-icon btn-light">
                    <img alt="Logo" src="/media/royaume-uni.png" class="w-50" />
                </a>
            </div>
            <div
                class="cursor-pointer symbol symbol-30px symbol-md-40px"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="bottom"
            >
                <a href="#" class="btn btn-icon btn-primary">
                    <i class="fas fa-user-circle fs-2"></i>
                </a>
            </div>
            <KTUserMenu></KTUserMenu>
            <!--end::Menu-->
        </div>
        <!--end::User -->

        <!--begin::Heaeder menu toggle-->
        <div class="d-flex align-items-center d-lg-none ms-2 me-n3" title="Show header menu">
            <div class="btn btn-icon btn-active-light-primary" id="kt_header_menu_mobile_toggle">
                <span class="svg-icon svg-icon-1">
                    <inline-svg src="/media/icons/duotune/text/txt001.svg" />
                </span>
            </div>
        </div>
        <!--end::Heaeder menu toggle-->
    </div>
    <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
    name: "topbar",
    components: {
        KTUserMenu,
    },
    setup() {
        const i18n = useI18n();
        const setLocale = (locale) => {
            localStorage.locale = locale;
            i18n.locale.value = locale;
        };
        const getLocaleLang = () => {
            return localStorage.locale;
        };
        return {
            setLocale,
            getLocaleLang,
        };
    },
});
</script>
