
import { defineComponent } from "vue";
import { toolbarWidthFluid } from "@/core/helpers/config";

export default defineComponent({
    name: "KToolbar",
    props: {
        breadcrumbs: Array,
        title: String,
    },
    components: {},
    setup() {
        return {
            toolbarWidthFluid,
        };
    },
});
